/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    pre: "pre",
    code: "code",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "default",
    style: {
      position: "relative"
    }
  }, "Default", React.createElement(_components.a, {
    href: "#default",
    "aria-label": "default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([opened, setOpen]) => (\n\t\t<Card>\n\t\t\t<Card.Section>\n\t\t\t\t<div className=\"d-f justify-content-between align-items-center\">\n\t\t\t\t\tName\n\t\t\t\t\t<BodyText subdued>(800) 555-1234</BodyText>\n\t\t\t\t\t{\n\t\t\t\t\t\topened\n\t\t\t\t\t\t? <Button primary small onClick={() => setOpen(!opened)}>Save</Button>\n\t\t\t\t\t\t: <Button primary small fill=\"subtle\" onClick={() => setOpen(!opened)}>Edit</Button>\n\t\t\t\t\t}\n\t\t\t\t</div>\n\t\t\t<Collapsible open={opened}>\n\t\t\t\t<Form className=\"m-t-2\">\n\t\t\t\t\t<Form.Input label=\"First Name\" placeholder=\"First Name\" />\n\t\t\t\t\t<Form.Input label=\"First Name\" placeholder=\"Last Name\" />\n\t\t\t\t\t<Form.Input label=\"Phone Number\" placeholder=\"(800) 555-1234\" />\n\t\t\t\t</Form>\n\t\t\t</Collapsible>\n\t\t\t</Card.Section>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "animate",
    style: {
      position: "relative"
    }
  }, "Animate", React.createElement(_components.a, {
    href: "#animate",
    "aria-label": "animate permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Collapsible can be configured to open and close with a transition effect. This is useful when the area being open is not large or a performance hit on slower computers."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={true}>\n\t{([opened, setOpen]) => (\n\t\t<Card>\n\t\t\t<Card.Section>\n\t\t\t\t<div className=\"d-f justify-content-between align-items-center\">\n\t\t\t\t\tName\n\t\t\t\t\t<BodyText subdued>(800) 555-1234</BodyText>\n\t\t\t\t\t{\n\t\t\t\t\t\topened\n\t\t\t\t\t\t? <Button primary small onClick={() => setOpen(!opened)}>Save</Button>\n\t\t\t\t\t\t: <Button primary small fill=\"subtle\" onClick={() => setOpen(!opened)}>Edit</Button>\n\t\t\t\t\t}\n\t\t\t\t</div>\n\t\t\t<Collapsible animate open={opened}>\n\t\t\t\t<Form className=\"m-t-2\">\n\t\t\t\t\t<Form.Input label=\"First Name\" placeholder=\"First Name\" />\n\t\t\t\t\t<Form.Input label=\"First Name\" placeholder=\"Last Name\" />\n\t\t\t\t\t<Form.Input label=\"Phone Number\" placeholder=\"(800) 555-1234\" />\n\t\t\t\t</Form>\n\t\t\t</Collapsible>\n\t\t\t</Card.Section>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "basic",
    style: {
      position: "relative"
    }
  }, "Basic", React.createElement(_components.a, {
    href: "#basic",
    "aria-label": "basic permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([opened, setOpen]) => (\n\t\t<div>\n\t\t\t<Button full fill=\"outline\" primary onClick={() => setOpen(!opened)}>\n\t\t\t\tWhat is Anvil?\n\t\t\t</Button>\n\t\t\t<Collapsible open={opened}>\n\t\t\t\t<BodyText className=\"m-t-2\">\n\t\t\t\t\tDesign guidelines and development tools for building products at ServiceTitan.\n\t\t\t\t</BodyText>\n\t\t\t</Collapsible>\n\t\t</div>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "accordion",
    style: {
      position: "relative"
    }
  }, "Accordion", React.createElement(_components.a, {
    href: "#accordion",
    "aria-label": "accordion permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A variation where only one or no section in a list of collapsible sections is open at a time."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([opened, setOpened]) => (\n\t\t<Card>\n\t\t\t{[1, 2, 3].map((card) => (\n\t\t\t\t<Card.Section key={card}>\n\t\t\t\t\t<div className=\"d-f justify-content-between align-items-center\">\n\t\t\t\t\t\t<Headline className=\"m-0\">Section Title {card}</Headline>\n\t\t\t\t\t\t<Button\n\t\t\t\t\t\t\ticonName={card === opened ? \"expand_less\" : \"expand_more\"}\n\t\t\t\t\t\t\tsmall\n\t\t\t\t\t\t\tfill=\"subtle\"\n\t\t\t\t\t\t\tonClick={() => setOpened(opened === card ? 0 : card)}\n\t\t\t\t\t\t/>\n\t\t\t\t\t</div>\n\t\t\t\t\t<Collapsible animate open={card === opened}>\n\t\t\t\t\t\t<BodyText className=\"m-t-2\">\n\t\t\t\t\t\t\tCollapsible content.\n\t\t\t\t\t\t\tLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor\n\t\t\t\t\t\t\tincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud\n\t\t\t\t\t\t\texercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute\n\t\t\t\t\t\t\tirure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla\n\t\t\t\t\t\t\tpariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia\n\t\t\t\t\t\t\tdeserunt mollit anim id est laborum.\n\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t</Collapsible>\n\t\t\t\t</Card.Section>\n\t\t\t))}\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "when-to-collapse-content",
    style: {
      position: "relative"
    }
  }, "When to collapse content", React.createElement(_components.a, {
    href: "#when-to-collapse-content",
    "aria-label": "when to collapse content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In general, content should not be collapsed by default, as it can hide important information and require additional interactions to access it. Some use general use cases of collapsed content include:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When the content has secondary value on the page, such as viewing details on a single item."), "\n", React.createElement(_components.li, null, "When space is cramped, particularly on smaller displays."), "\n", React.createElement(_components.li, null, "When information is not yet relevant, such as in a ", React.createElement(_components.a, {
    href: "/components/flow-card/"
  }, "Flow Card"), "."), "\n", React.createElement(_components.li, null, "When using, the preferred control for the component is a ", React.createElement(_components.a, {
    href: "/components/button/"
  }, "Button"), " variation."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Collapsible } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
